.category-container {  
    .category-card {
      .category-repeater {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .category-card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid #e0e0e0;
          padding: 1rem;
          a {
            display: block;
            width: 315px;
            height: 200px;
            overflow: hidden;
            
            .category-image {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
  
          h3 {
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }
  }
  
  .pointer {
    cursor: pointer;
  }