.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  gap: 0px;
  justify-content: center;
}

.product-type-child {
  width: 100%;
  border: 1px solid #e5e5e5;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-type-child:hover {
  border: 1px solid #a3a3a3;
  text-decoration: underline !important;
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  height: 225px;
  width: 225px;
}

.product-image {
  height: 125px;
  width: 125px;
  object-fit: contain;
}

.listingTopbar_cardImage {
  width: 4rem;
  height: 4rem;
}

.product-title h4 {
  margin-top: 8px;
}

.link-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
