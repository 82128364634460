header nav {
  .d-none.d-lg-flex.justify-content-end.col-9 {
    width: 550px;
  }

  form {
    width: 100%;

    .predictive-search-container {
      .products-results-container {
        display: none;
      }

      .products-results {
        border: solid 1px #eee;

        &:hover {
          background: #fafafa;
          border-color: #ccc;
        }
      }

      .search-card-image {
        aspect-ratio: 1 / 1;
      }

      hr {
        display: none;
      }
    }
  }
}
