.productDetail_table {
  border-collapse: collapse;
  width: 100%;
  background-color: #ffffff;
  border-spacing: 0;
  td,
  th {
    text-align: left;
    padding: 8px !important;
  }
  tr.table-primary td:first-child {
    font-weight: 600;
  }
  &:not(:first-child) {
    border-top: 1px #e1e1e1 solid;
  }
}

.link {
  color: #007EB1;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
  font-weight: bold;
}

.productTypeImage{
  width: 100%;
  height: 0px;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
}

.availableTag {
  padding: 3px;
  width: fit-content;
  border: 2px solid #007EB1;
  border-radius: 10px;
  margin-left: 5px;
  background-color: #007EB1;
  color: white;
}

.availableOrderTag {
  padding: 5px 10px;
  width: fit-content;
  border: 2px solid #007EB1;
  font-weight: bold;
  border-radius: 10px;
  margin-left: 5px;
}

.col-sm-12.col-lg-4.offset-lg-1.mb-2 {
  .row {
    .preview-item {
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              .productImage {
                border-radius: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.product-bg-grey {
  background-color: #DFE3E6;
}

.product-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.71429rem;
  position: relative;
  padding: 0 1.5rem;
  width: 100%;
}

@media (min-width: 1261px) {
  .product-container {
    padding: 0 6rem;
  }
}

.product-loading-spinner {
  width: 1.5rem;
  height: 1.5rem;
}

.gallery-column {
  // only on medium and large screens
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
