.brand-header {
  display: flex;
  align-items: center;

  .brand-image {
    width: 100px;
    height: 100px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: solid 1px #eee;
  }

  h2 {
    padding: 0 15px 0 30px;
    margin: 0;
  }
}

.brand-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media(min-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  .brand-list-item {
    border: solid 1px #eee;
    padding: 15px;
    text-decoration: none;
    border-radius: 8px;

    h4 {
      padding: 0;
      margin: 0 0 10px;
      span{
        font-size: 80%;
        float: right;
        margin: -6px -6px 0 0;
        color: #666;
        background: #eee;
        padding: 0 10px;
        line-height: 30px;
        border-radius: 15px;
      }
    }

    &:hover {
      background: #fafafa;
      border-color: #ccc;
    }

    .product-type-name-path {
      font-size: 90%;
      color: #666;
      padding: 0;
      margin: 0;
    }
  }
}